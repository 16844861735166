import React, { ReactNode } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { twMerge } from "tailwind-merge";
import { FreeMode } from "swiper/modules";

interface SwipeCardsProps {
  items: ReactNode[];
  slidesPerView?: number;
  className?: string;
  hasNextPage?: boolean;
  onLoadMore?: () => void;
  isLoading?: boolean;
}

const SwipeCards: React.FC<SwipeCardsProps> = ({
  items,
  slidesPerView = 1.2,
  className = "",
  hasNextPage = false,
  onLoadMore,
  isLoading = false,
}) => {
  const mergedClasses = twMerge("swiper-container", className);

  const loadMoreCard = hasNextPage && (
    <SwiperSlide
      style={{
        //this ensures responsive width on mobile ==> Fix for Swiper library issue: initially loads with PC width causing CLS;
        width: 100 / slidesPerView - 12 / slidesPerView + `vw`,
        marginRight: "16px",
        height: "auto",
      }}
    >
      <div className="flex h-full">
        <div
          className="flex w-full mb-4 items-center justify-center bg-white border border-gray-200 rounded-lg cursor-pointer shadow-lg"
          onClick={!isLoading ? onLoadMore : undefined}
        >
          <div className="p-4 text-center text-gray-800 font-medium">
            {isLoading ? "Loading..." : "Load More"}
          </div>
        </div>
      </div>
    </SwiperSlide>
  );

  return (
    <div className="block md:hidden -ml-8">
      <Swiper
        spaceBetween={16}
        slidesPerView={"auto"} // cards visible + peek
        className={mergedClasses}
        freeMode={{
          enabled: true,
          sticky: true,
          momentumBounce: false,
        }}
        resistanceRatio={0}
        slidesOffsetBefore={0}
        slidesOffsetAfter={0}
        modules={[FreeMode]}
        style={{ height: "auto", paddingRight: 16, paddingLeft: 32 }}
      >
        {items.map((item, index) => (
          <SwiperSlide
            key={index}
            style={{
              //Fix for Swiper library issue: swiper library fix render issue in first render
              width: 100 / slidesPerView - 12 / slidesPerView + `vw`,
              marginRight: "16px",
            }}
          >
            {item}
          </SwiperSlide>
        ))}
        {loadMoreCard}
      </Swiper>
    </div>
  );
};

export default SwipeCards;
