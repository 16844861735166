import React, { ReactNode } from "react";
import { FC } from "react";
import { TourCardFragment, ToursQueryVariables } from "@graphql/types";
import classNames from "classnames";
import { Title } from "@components/ui/Title";
import TourCard from "../TourCard";
import { Paragraph } from "@components/ui/Paragraph";
import {
  IBadgeProps,
  SupplierBadges,
} from "@components/pages/TourPage/auth/SupplierBadges";
import { Trans } from "@helpers/translate";
import SwipeCards from "@components/common/Carousel/swipeCards";

interface Props extends ToursQueryVariables {
  title?: string;
  data?: TourCardFragment[];
  columns?: "4" | "3" | "2";
  showUser?: boolean;
  subtitle?: string;
  onRatingClicked?: () => void;
  children?: ReactNode;
  badgesAvailable?: IBadgeProps[];
  enableSwipeOnMobile?: boolean;
  hasNextPage?: boolean;
  onLoadMore?: () => void;
  isLoading?: boolean;
}

const ToursBlock: FC<Props> = ({
  title,
  subtitle,
  data,
  children,
  badgesAvailable,
  showUser = true,
  onRatingClicked,
  columns = "4",
  enableSwipeOnMobile = false,
  hasNextPage,
  onLoadMore,
  isLoading,
}) => {
  if (!data?.length) {
    return null;
  }

  const className = classNames(
    `grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 ${
      subtitle ? "mt-0" : "mt-10"
    }`,
    {
      "lg:grid-cols-3": columns === "3",
      "lg:grid-cols-4": columns === "4",
      "lg:grid-cols-2": columns === "2",
    }
  );
  const cards = data.map((node) => (
    <TourCard
      key={node.id}
      tour={node}
      showUser={showUser}
      onRatingClicked={onRatingClicked}
      ratingVariant={"stars"}
      variant="vertical"
    />
  ));

  return (
    <div className="mb-10">
      {title && (
        <Title as="h2" size="md" divider className="mb-0">
          {title}
        </Title>
      )}
      {subtitle && (
        <Paragraph size="md" className="mt-4">
          {subtitle}
        </Paragraph>
      )}
      {badgesAvailable && badgesAvailable.length > 0 && (
        <div className="my-4">
          {title && (
            <h3 className="text-xl font-semibold mb-4">
              <Trans id="supplier.categories.subtitle">Categories</Trans>
            </h3>
          )}
          <SupplierBadges tourBadges={badgesAvailable} size="sm" />
        </div>
      )}
      {enableSwipeOnMobile && (
        <SwipeCards
          className={"mt-8"}
          items={cards}
          slidesPerView={1.2}
          hasNextPage={hasNextPage}
          onLoadMore={onLoadMore}
          isLoading={isLoading}
        />
      )}
      <div
        className={classNames(
          enableSwipeOnMobile ? "hidden md:grid" : "",
          className
        )}
      >
        {cards}
      </div>
      {children && <div className="text-center mt-8">{children}</div>}
    </div>
  );
};

export default ToursBlock;
